.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* theme css */

a{
  color: currentColor;
  text-decoration: none;
}
.btns{
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
}

.button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  }
  
  
  .button:hover{
    text-decoration: none;
  }

  .button:focus,
  .button.focus {
    outline: 0;
    box-shadow:'none' 
  }

  .button.disabled,
  .button:disabled {
    opacity: 0.5;
    box-shadow:none;
  }

  .button:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  .button:not(:disabled):not(.disabled):active,
  .button:not(:disabled):not(.disabled).active {
    background-image: none;
    box-shadow:'none'
  }

.button-outline-primary{
    color: #6551FF;
    background-color: transparent;
    background-image: none;
    border-color: #6551FF;
}

/* dropdown css */
.menu-dropdown{
  position: relative;
}
.dropdown-toggle{
  display: flex;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  align-items: center;
  font-size: 1rem;
  font-family: VodafoneLt-Regular;
  color: currentColor;
  /* height:80px */
}

.dropdown-menu{
  list-style: none;
  margin: 0;
  padding: 0;
    background: transparent;
    min-width: 140px;
    position: absolute;
    top: 100%;
    z-index: 1;
    padding: 8px;
    box-sizing: border-box;
    z-index: 11111;
}

