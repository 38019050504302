@font-face {
  font-family: 'brandon-grotesque';
  src: url('./fonts/brandon-grotesque-regular.eot');
  src: url('./fonts/brandon-grotesque-regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/brandon-grotesque-regular.woff2') format('woff2'),
    url('./fonts/brandon-grotesque-regular.woff') format('woff'),
    url('./fonts/brandon-grotesque-regular.ttf') format('truetype'),
    url('./fonts/brandon-grotesque-regular.svg#brandon-grotesque') format('svg');
}

@font-face {
  font-family: VodafoneLt-Regular;
  src: url('./fonts/VodafoneLt-Regular.ttf');
}

@font-face {
  font-family: VodafoneRg-Regular;
  src: url('./fonts/VodafoneRg-Regular.ttf');
}

@font-face {
  font-family: VodafoneRg-Bold;
  src: url('./fonts/VodafoneRg-Bold.ttf');
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfaff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
